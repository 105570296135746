import md5 from "js-md5";
import { initUtils } from "@telegram-apps/sdk";
import { Toast } from "antd-mobile";
import { commit } from "./commit";
import { Address } from "@ton/ton";
const utils = initUtils();

export const openTelegramLink = async (url: string) => {
  return utils.openTelegramLink(url);
};

export const openLink = async (url: string) => {
  return utils.openLink(url);
};
export function generateRandomString(length = 6) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function getInviteLink(
  userId: string | number,
  type = 1,
  repalyContent = ""
) {
  const replay1 = `🐷💸Daily $TON giveaway by inviting friends at PIGG GAME!! \n🐷🤩Not to mention ALL $TON spent during Alpha will be REFUNDED! `;
  const replay2 = "🐷⚔️Now is the best time to join! Fight for $PIGG!";
  const replay =
    encodeURI(repalyContent) || encodeURI(`${replay1} \n${replay2}`);
  const isDev =
    import.meta.env.VITE_MODEL === "development" ||
    import.meta.env.VITE_MODEL === "preview";
  const startappParam = type === 1 ? "r" : "gift";
  const baseUrl = isDev
    ? "t.me/twa_stage_bot/pigg"
    : "t.me/PiggGame_bot/piggcoin";
  const url = `https://t.me/share/url?text=${replay}&url=${baseUrl}?startapp=${startappParam}${userId}`;
  openTelegramLink(url);
  commit("invite_Friend");
}
export const DEFAULT_AVATAR = "/imgs/earn.jpg";
export const getAvatar = (avatar: string | number) => {
  try {
    const avatarMd = md5(avatar + "");
    const url = `https://image.googlescdn.com/${avatarMd}`;
    return url;
  } catch (error) {
    console.log(error, "error");
    return DEFAULT_AVATAR;
  }
};
export function loadImg(src: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve();
    img.onerror = (err) => reject(err);
  });
}

export function fileToBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

export async function saveFileToLocalStorage(file: File) {
  const base64 = (await fileToBase64(file)) as string;
  localStorage.setItem("myFile", base64);
}

export async function getFileFromLocalStorage() {
  const base64 = localStorage.getItem("myFile");
  if (base64) {
    const response = await fetch(base64);
    const blob = await response.blob();
    return new File([blob], "image.jpg", { type: "image/jpeg" });
  }
  return null;
}
enum TaskStatus {
  GoTo = 1, // Go to
  Receive = 2, // Receive
  Finish = 3, // Finish
  Check = 4, // Check
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTaskStatus = (item: any): TaskStatus => {
  if (!item?.target?.is_completion_target) {
    return TaskStatus.GoTo;
  }
  if (
    item?.target?.is_completion_target &&
    item?.target?.is_reward_collection
  ) {
    return TaskStatus.Finish;
  }

  if (
    item?.target?.is_completion_target &&
    !item?.target?.is_reward_collection
  ) {
    return TaskStatus.Receive;
  }
  if (
    item?.target?.is_completion_target &&
    item?.target?.is_reward_collection
  ) {
    return TaskStatus.Check;
  }

  return TaskStatus.GoTo;
};

export const MyToast = (content: string) => {
  return Toast.show({
    content,
    maskStyle: {
      transform: "translateY(-50px)",
    },
  });
};

export const shareTwiterLink = (id: string | number) => {
  const botUrls = {
    prod: "t.me/PiggGame_bot/piggcoin",
    dev: "t.me/twa_stage_bot/pigg",
  };
  const isDev = ["development", "preview"].includes(import.meta.env.VITE_MODEL);
  const baseUrl = isDev ? botUrls.dev : botUrls.prod;
  const inviteLink = `${baseUrl}?startapp=r${id}`;

  const inviteMessages = {
    main: `🐷 Game On! @Pigg_Game is made for the bravest @ton_blockchain users! Fight and earn prizes!`,
  };
  const copyText = `https://twitter.com/intent/tweet?hashtags=TON,PIGG&text=${inviteMessages.main}&url=${inviteLink}`;
  // navigator.clipboard.writeText(copyText);
  openLink(copyText);
};

export const formattedNumbers = (numbers: string | number) => {
  const num = parseInt(numbers + "", 10);

  if (num >= 1_000_000_000_000) {
    return `${(num / 1_000_000_000).toFixed(2)}b`;
  }
  if (num >= 1_000_000_000) {
    return `${(num / 1_000_000).toFixed(2)}m`;
  }
  if (num >= 1_000_000) {
    return `${(num / 1_000).toFixed(2)}k`;
  }
  return num.toLocaleString();
};
