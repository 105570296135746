export const imagesArray: Array<Record<string, string>> = [
  { name: "hint3", path: "/imgs/test/hint3.gif" },
  { name: "hint3", path: "/imgs/test/hint2.gif" },
  { name: "hint3", path: "/imgs/test/hint1.gif" },
  { name: "hint3", path: "/imgs/task/badge_todo.png" },
  { name: "hint3", path: "/imgs/task/portal.png" },
  { name: "hint3", path: "/imgs/task/decrease.png" },
  { name: "hint3", path: "/imgs/task/increase.png" },
  { name: "hint3", path: "/imgs/task/portal.gif" },
  { name: "hint3", path: "/imgs/friend/share.png" },
  // { name: "hint3", path: "/imgs/rank/recruit_multiple.png" },
  { name: "hint3", path: "/imgs/character/H1_1.png" },
  { name: "hint3", path: "/imgs/character/H1_2.png" },
  { name: "hint3", path: "/imgs/character/H1_3.png" },
  { name: "hint3", path: "/imgs/character/H1_4.png" },
  { name: "hint3", path: "/imgs/character/H1_5.png" },
  { name: "hint3", path: "/imgs/character/H2_1.png" },
  { name: "hint3", path: "/imgs/character/H2_2.png" },
  { name: "hint3", path: "/imgs/character/H2_3.png" },
  { name: "hint3", path: "/imgs/character/H2_4.png" },
  { name: "hint3", path: "/imgs/character/H2_5.png" },
  { name: "hint3", path: "/imgs/character/H3_1.png" },
  { name: "hint3", path: "/imgs/character/H3_2.png" },
  { name: "hint3", path: "/imgs/character/H3_3.png" },
  { name: "hint3", path: "/imgs/character/H3_4.png" },
  { name: "hint3", path: "/imgs/character/H3_5.png" },
  { name: "hint3", path: "/imgs/character/H4_1.png" },
  { name: "hint3", path: "/imgs/character/H4_2.png" },
  { name: "hint3", path: "/imgs/character/H4_3.png" },
  { name: "hint3", path: "/imgs/character/H4_4.png" },
  { name: "hint3", path: "/imgs/character/H4_5.png" },
  { name: "hint3", path: "/imgs/character/H5_1.png" },
  { name: "hint3", path: "/imgs/character/H5_2.png" },
  { name: "hint3", path: "/imgs/character/H5_3.png" },
  { name: "hint3", path: "/imgs/character/H5_4.png" },
  { name: "hint3", path: "/imgs/character/H5_5.png" },
  { name: "hint3", path: "/imgs/rank/rank_first.png" },
  { name: "hint3", path: "/imgs/rank/rank_second.png" },
  { name: "hint3", path: "/imgs/rank/rank_third.png" },
  { name: "hint3", path: "/imgs/rank/rank_bg.png" },
  { name: "hint3", path: "/imgs/couponBig.png" },
  { name: "hint3", path: "/imgs/bubble.png" },
  { name: "hint3", path: "/imgs/news/Airdrop.png" },
  { name: "hint3", path: "/imgs/news/Airdrop_2.gif" },
  { name: "hint3", path: "/imgs/news/light.png" },
  { name: "hint3", path: "/imgs/news/Airdrop_1.png" },
  { name: "hint3", path: "/imgs/gamebox/selected.png" },
];

export const initArray: Array<Record<string, string>> = [
  { name: "home", path: "/imgs/tabbar/home.png" },
  { name: "home_off", path: "/imgs/tabbar/home_1.png" },
  { name: "rank", path: "/imgs/tabbar/rank.png" },
  { name: "rank_off", path: "/imgs/tabbar/rank_1.png" },
  { name: "task", path: "/imgs/tabbar/task.png" },
  { name: "task_off", path: "/imgs/tabbar/task_1.png" },
  { name: "friend", path: "/imgs/tabbar/friend.png" },
  { name: "friend_off", path: "/imgs/tabbar/friend_1.png" },
  { name: "hand", path: "/imgs/test/hand.png" },
  { name: "loot", path: "/imgs/header/loot.png" },
  { name: "ton", path: "/imgs/header/bg_2.jpg" },
  { name: "prizepool", path: "/imgs/header/prizepool.png" },
  { name: "dg_rank", path: "/imgs/header/dg_rank.png" },
  { name: "coupon", path: "/imgs/header/coupon.png" },
  { name: "add_1", path: "/imgs/header/add_1.png" },
  { name: "ton", path: "/imgs/header/settings.png" },
  { name: "ton", path: "/imgs/header/rule.png" },
  { name: "ton", path: "/imgs/news/Airdrop_3.png" },
];
