import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { retrieveLaunchParams } from "@telegram-apps/sdk";
import { getStorage } from "@/utils/storage";
import { userLogin } from "./user";
const $config = import.meta.env;
const requestUrl = $config.VITE_BASE_URL;
const { initDataRaw } = retrieveLaunchParams();
let source = axios.CancelToken.source();
const instance = axios.create({
  baseURL: requestUrl,
  timeout: 20000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});
let pendingRequests: Array<{
  config: AxiosRequestConfig;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resolve: (value: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reject: (reason: any) => void;
}> = [];
const initData = initDataRaw;
const whiteUrlList = ["login"];
instance.interceptors.request.use(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function (config: any) {
    const token = await Promise.resolve(getStorage("token"));
    let flag = false;
    try {
      flag = whiteUrlList.some((item) => config?.url.endsWith(item));
    } catch (error) {
      console.log(error);
    }
    if (token && !flag) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    if (typeof config.data == "object") {
      config.data.time = (+new Date() / 1000) >> 0;
    }
    const lang = localStorage.getItem("i18nextLng_code");
    config.headers["Tg-Token"] = `${initData}`;
    config.headers["lan"] = lang || "en";
    config.cancelToken = source.token;
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
const createLoginHandler = () => {
  let lastTime = 0;

  return async () => {
    const now = Date.now();
    if (now - lastTime > 2000) {
      lastTime = now;
      await userLogin();
      pendingRequests.forEach(({ config, resolve, reject }) => {
        instance(config)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
      pendingRequests = [];
    }
  };
};
instance.interceptors.response.use(
  async (response: AxiosResponse) => {
    console.log(response, "response");
    const url = response?.config?.url;
    if (url?.endsWith("login") && response?.data?.code != 0) {
      if (response?.data?.code == 403) {
        throw new Error("403");
      } else {
        throw new Error("other");
      }
    }

    if (response?.data?.code === 403) {
      const handleLogin = createLoginHandler();
      await handleLogin();
      // throw new Error("403");
      return new Promise((resolve, reject) => {
        pendingRequests.push({ config: response.config, resolve, reject });
      });
    }
    return response;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (err: any) => {
    return Promise.reject(err);
  }
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const request: any = {};
request.cancel = (msg = "") => {
  source.cancel(msg);
  source = axios.CancelToken.source();
};
request.post = (
  url: string,
  params: Record<string, unknown>,
  config = {
    headers: {
      "Content-Type": "application/json",
    },
  }
) => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, params, config)
      .then(async (res) => {
        const ret: AxiosRequestConfig<unknown> = res;
        return resolve(ret.data);
      })
      .catch((err) => {
        console.log(err, "err1");
        return reject(err);
      });
  });
};
request.get = (url: string, params: Record<string, unknown>) => {
  return new Promise((resolve, reject) => {
    let query = "";
    if (params) {
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          query += `${encodeURIComponent(key)}=${encodeURIComponent(
            params[key] as string
          )}&`;
        }
      }
      query = query.slice(0, -1);
    }
    const resultUri = query ? `${url}?${query}` : url;
    instance
      .get(resultUri)
      .then(async (res) => {
        // if (res.data.code == 403) {
        //   location.reload();
        // }
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

request.put = (
  url: string,
  params: Record<string, unknown>,
  config = {
    headers: {
      "Content-Type": "application/json",
    },
  }
) => {
  return new Promise((resolve, reject) => {
    instance
      .put(url, params, config)
      .then(async (res) => {
        const ret: AxiosRequestConfig<unknown> = res;
        // if (res.data.code == 403) {
        //   location.reload();
        // }
        return resolve(ret.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};
export default request;
