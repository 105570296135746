import ReactDOM from "react-dom/client";
import { StrictMode } from "react";
import { retrieveLaunchParams } from "@telegram-apps/sdk-react";

import { EnvUnsupported } from "@/components/EnvUnsupported";
import { init } from "@/utils/init";
import App from "./root/App";
import "@telegram-apps/telegram-ui/dist/styles.css";
import "./config/css/global.css";

// Mock the environment in case, we are outside Telegram.
import "./mockEnv.ts";
const isDev = ["development", "preview"].includes(import.meta.env.VITE_MODEL);
const root = ReactDOM.createRoot(document.getElementById("root")!);

try {
  // Configure all application dependencies.
  init(retrieveLaunchParams().startParam === "debug" || isDev);

  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
} catch (e) {
  console.log(e);
  root.render(<EnvUnsupported />);
}
