const $config = import.meta.env;
const commonKey = $config.VITE_APP_NAME;

type BasicType = string | number | boolean;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type StorageValue = BasicType | Record<string, BasicType> | null | any;
export function setStorage(key: string, value: StorageValue = "") {
  if (!key) {
    return console.error("key undefined");
  }
  if (value === undefined || value === null) {
    console.error("value is null or undefined");
  }
  key = `${commonKey}_${key}`;
  try {
    const serializedValue =
      typeof value === "object" ? JSON.stringify(value) : String(value);

    window.localStorage.setItem(key, serializedValue);
  } catch (err) {
    console.error("Failed to serialize value:", err);
  }
}

export function getStorage(key: string): StorageValue | undefined {
  if (!key) {
    console.error("key undefined");
    return;
  }

  key = `${commonKey}_${key}`;

  try {
    const storedValue = window.localStorage.getItem(key);
    if (storedValue === null) {
      return null;
    }
    try {
      return JSON.parse(storedValue);
    } catch {
      return storedValue;
    }
  } catch (err) {
    console.error("Failed to retrieve or parse value:", err);
    return undefined;
  }
}
export function clearStorage() {
  window.localStorage.clear();
}
export function rmStorage(key: string) {
  if (!key) {
    return console.error("key undefined");
  }
  key = `${commonKey}_${key}`;
  window.localStorage.removeItem(key);
}
