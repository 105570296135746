import { useEffect, useMemo, type FC } from "react";
import { AppRoot } from "@telegram-apps/telegram-ui";
import UseInitTgInit from "@/hooks/useTginit";
import { useUserDataStore } from "@/store/user";
import { TabBar as AntdTabbar, SafeArea } from "antd-mobile";
import { useBaseDataStore } from "../store/baseData";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import ErrorBoundary from "@/components/ErrorBoundary/ErrorBoundary";
// import LoadPage from "@/components/LoadPage/LoadPage";
import "@aarsteinmedia/dotlottie-player";
import { userLogin, getUserInfo, getUserInitInfo } from "@/api/user";
import {
  useNavigate,
  useLocation,
  BrowserRouter as Router,
} from "react-router-dom";
import AppRoutes, { routes, tabsRoute } from "./AppRoute";
import { useLaunchParams, miniApp, useSignal } from "@telegram-apps/sdk-react";
import useRem from "@/hooks/useRem";
import { imagesArray, initArray } from "../resource/index";
import "@telegram-apps/telegram-ui/dist/styles.css";
import { loadImg } from "@/utils";
import i18n from "@locales/i18next";
import { I18nextProvider } from "react-i18next";
import "./App.css";
import styles from "./App.module.css";
import "./tabbar.scss";
import { useInitStore } from "@/store/init";
import { getUserHeroList } from "@/api/hero";
import { getStorage } from "@/utils/storage";
import { commit } from "@/utils/commit";
import { Buffer } from "buffer";
window.Buffer = Buffer;
const Bottom: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const setRouteActive = (value: string) => {
    navigate(value);
  };

  return (
    <AntdTabbar
      activeKey={pathname}
      onChange={(value) => setRouteActive(value)}
    >
      {tabsRoute.map((item) => (
        <AntdTabbar.Item
          key={item.path}
          icon={item.icon}
          title={item.tabbarTitle}
        />
      ))}
    </AntdTabbar>
  );
};

const App: FC = () => {
  // const [currentStep, setCurrentStep] = useState(0);
  // const [loading, setLoading] = useState(true);
  commit("entry_loading", {
    props: { UA: window.UA, Model: window.styleModel },
  });
  UseInitTgInit();
  const lp = useLaunchParams();
  const isDark = useSignal(miniApp.isDark);
  const updateBaseinfo = useUserDataStore((state) => state.updateBaseinfo);
  const updateImgobj = useBaseDataStore((state) => state.updateImgobj);
  const updateInitInfo = useInitStore((state) => state.updateInitInfo);
  const updateHeroList = useInitStore((state) => state.updateHeroList);
  const updateUserinfoKey = useUserDataStore(
    (state) => state.updateUserinfoKey
  );
  async function init() {
    const imgObj: Record<string, string> = {};
    // const itemAdd = 100 / initArray.length - 2;
    const loadInitialImages = initArray.map(async (item) => {
      const { path, name } = item;
      try {
        await loadImg(path);
        imgObj[name] = path;
        // setCurrentStep((prev) => prev + itemAdd);
      } catch (error) {
        console.error(`Failed to load image ${name}:`, error);
      }
    });
    const loadRemainingImages = () => {
      imagesArray.forEach(({ path, name }) => {
        loadImg(path)
          .then(() => {
            imgObj[name] = path;
          })
          .catch((error) => {
            console.error(`Failed to load image ${path}:`, error);
          });
      });
    };
    await Promise.all(loadInitialImages);
    updateImgobj(imgObj);
    setTimeout(loadRemainingImages, 500);
    try {
      let invite_id = "";
      const params = lp.startParam;
      if (params?.includes("r")) {
        invite_id = params.split("r")[1];
      }
      const res = await userLogin(invite_id);
      const loginToken = res?.auth_token;
      const token = getStorage("token");
      const fn = async () => {
        const [userInfoRes, heroListRes, initInfoRes] = await Promise.all([
          getUserInfo(),
          getUserHeroList(),
          getUserInitInfo(),
        ]);
        if (userInfoRes.code === 0) {
          updateBaseinfo(userInfoRes.data);
        } else {
          console.log(userInfoRes, "res");
        }
        if (heroListRes) {
          updateHeroList(heroListRes);
        }
        if (initInfoRes) {
          updateInitInfo(initInfoRes);
        }
        updateUserinfoKey({
          isDailyFristLogin: true,
        });
        commit("loading_over", {
          props: { UA: window.UA, Model: window.styleModel },
        });
        const isRecruitGuide =
          userInfoRes.data.score <= 0 && !heroListRes.length;
        updateUserinfoKey({
          isRecruitGuide,
        });
      };
      if (token !== loginToken) {
        setTimeout(fn, 50);
        return;
      }
      fn();
      // setCurrentStep(100);
      // setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  useRem(375, 16);
  useEffect(() => {
    miniApp.setHeaderColor("#000000");
    init();
    document.body.dir = i18n.dir();
    // if (process.env.NODE_ENV !== "production") {
    //   import("vconsole").then(({ default: VConsole }) => {
    //     new VConsole();
    //   });
    // }
  }, []);
  return (
    <I18nextProvider i18n={i18n}>
      {/* {!loading ? (
        <AppRoot
          appearance={miniApp.isDark ? "dark" : "light"}
          platform={["macos", "ios"].includes(lp.platform) ? "ios" : "base"}
        >
          <Router>
            <AppContent />
          </Router>
        </AppRoot>
      ) : (
        <LoadPage currentStep={currentStep}></LoadPage>
      )} */}
      <AppRoot
        appearance={isDark ? "dark" : "light"}
        platform={["macos", "ios"].includes(lp.platform) ? "ios" : "base"}
      >
        <Router>
          <AppContent />
        </Router>
      </AppRoot>
    </I18nextProvider>
  );
};
const AppContent: React.FC = () => {
  const location = useLocation();
  const currentRoute = routes.find((route) => route.path === location.pathname);
  const isTabsRoute =
    currentRoute &&
    (tabsRoute.some((tab) => tab.path === currentRoute.path) ||
      currentRoute.path === "/");
  return (
    <div className={styles.app}>
      <div className={styles.appBody}>
        <AppRoutes />
      </div>
      {isTabsRoute && (
        <div className={styles.bottom}>
          <Bottom />
        </div>
      )}
      <SafeArea position="bottom"></SafeArea>
    </div>
  );
};

const Inner: FC = () => {
  // let debug = false;
  const manifestUrl = useMemo(() => {
    return new URL("tonconnect-manifest.json", window.location.href).toString();
  }, []);
  console.log(manifestUrl, "manifestUrl");

  try {
    // const lp = useLaunchParams();
    // debug = lp?.startParam === "debug";
  } catch (error) {
    console.log(error, 1);
  }
  return (
    <TonConnectUIProvider manifestUrl={manifestUrl}>
      {/* <SDKProvider acceptCustomStyles debug={debug}> */}
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
      {/* </SDKProvider> */}
    </TonConnectUIProvider>
  );
};

const Root: FC = () => <Inner />;

export default Root;
