export default function Error(props: {
  message: string;
  type: "403" | "other";
}) {
  const { message, type } = props;
  const str =
    type !== "403"
      ? "In order to give everyone a better gaming experience, we are conducting regular or emergency maintenance, updating game content to solve game problems, or adding in-game features, so stay tuned."
      : "Your account was logged in on another device. The previous device has been logged out.";
  const title = type === "403" ? "Multi-stage login" : "Game maintenance";
  console.log(message, type);

  return (
    <div className="text-white py-[220px] text-center flex flex-col items-center justify-around text-[20px] w-screen h-screen rank_page_img">
      <div className="relative">
        <img
          src="/imgs/character/H1_1.png"
          className="w-[175px] h-[180px]"
          alt=""
        />
        <div className="absolute top-[-50px] text-black flex justify-center items-center  right-[-60px] w-[125px] h-[115px]">
          <img
            src="/imgs/bubble.png"
            alt=""
            className="w-[125px] h-[115px] absolute top-0 left-0"
          />
          <div className="text-[14px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-100%] z-2">
            {type === "403" ? "multiple login" : "maintenance"}
          </div>
        </div>
      </div>
      <div className="text-[32px] text-thc text-center mt-5">{title}</div>
      <div className="text-[14px] w-[340px] text-white">{str}</div>
      {type !== "403" && (
        <div
          className="text-[#FFD800] underline mt-[10px]"
          onClick={() => window.location.reload()}
        >
          Reload
        </div>
      )}
    </div>
  );
}
