import {
  backButton,
  viewport,
  themeParams,
  miniApp,
  initData,
  $debug,
  settingsButton,
  init as initSDK,
  postEvent,
} from "@telegram-apps/sdk-react";

/**
 * Initializes the application and configures its dependencies.
 */
export function init(debug: boolean): void {
  // Set @telegram-apps/sdk-react debug mode.
  $debug.set(debug);

  // Initialize special event handlers for Telegram Desktop, Android, iOS, etc.
  // Also, configure the package.
  initSDK();

  // Add Eruda if needed.
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  debug &&
    import("eruda").then((lib) => lib.default.init()).catch(console.error);

  // Check if all required components are supported.
  if (!backButton.isSupported() || !miniApp.isSupported()) {
    throw new Error("ERR_NOT_SUPPORTED");
  }
  // if (settingsButton.mount.isAvailable()) {
  //   settingsButton.mount();
  //   settingsButton.isMounted(); // true
  //   settingsButton.show();
  //  postEvent("web_app_request_fullscreen");
  // }

  // Mount all components used in the project.
  backButton.mount();
  miniApp.mount();
  themeParams.mount();
  initData.restore();
  void viewport
    .mount()
    .catch((e) => {
      console.error("Something went wrong mounting the viewport", e);
    })
    .then(() => {
      viewport.bindCssVars();
    });

  // Define components-related CSS variables.
  miniApp.bindCssVars();
  themeParams.bindCssVars();
  // try {
  //   postEvent("web_app_request_fullscreen");
  // } catch (error) {
  //   console.log(error);
  // }
}
