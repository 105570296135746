import React from "react";
import Error from "./error";
import { setStorage } from "@/utils/storage";

class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean; type: "403" | "other"; message: string }
> {
  errorInfo: string;
  constructor(props: { fallback: React.ReactNode; children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false, type: "other", message: "" };
    this.errorInfo = "";
  }

  static getDerivedStateFromError(error: Error) {
    console.log(error.message, "error", 4396);
    // this.type = error.message === "403" ? "403" : "other";
    const type = error.message === "403" ? "403" : "other";
    return { hasError: true, type, message: error.message };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log(error.message, "error.message");
    this.errorInfo = errorInfo.componentStack as string;
    console.error("Error caught in ErrorBoundary:", error, errorInfo);
    setStorage("errorInfo", this.errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <Error message={this.state.message} type={this.state.type} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
